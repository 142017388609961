@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {

  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  @font-face {
    font-family: "Nunito";
    src: url("./fonts/Nunito-Regular.ttf");
    font-weight: 400;
    font-style: normal;
  }

  @font-face {
    font-family: "Nunito";
    src: url("./fonts/Nunito-Medium.ttf");
    font-weight: 500;
    font-style: medium;
  }

  @font-face {
    font-family: "Nunito";
    src: url("./fonts/Nunito-Bold.ttf");
    font-weight: 700;
    font-style: bold;
  }

  @font-face {
    font-family: "Nunito";
    src: url("./fonts/Nunito-Light.ttf");
    font-weight: 300;
    font-style: light;
  }
}

body {
  margin: 0;
  font-family: "Nunito", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
